<template>
  <div class="mt-5 md:mt-8">
    <div class="container no-print">
      <LayoutColumns>
        <template #left>
          <div class="order-3 lg:order-none mt-4 md:mt-0">
            <CommonListLabel label="Historien i tal" />
            <CommonDivider dark />
            <ListArticles
              :count="2"
              :offset="1"
              type="articletype"
              :article-type="103"
              :reset-index="true"
              :same-size="false"
              :always-image="true"
              placement="side"
              :always-teaser="true"
            />
          </div>

          <AdsConceptAd class="lg:hidden order-8" id="mm-mob2" />
          <ListJobs class="order-2 lg:block" :count="5" :random="true" />
        </template>
        <template #main>
          <ListArticles
            class="order-1 lg:order-none"
            :offset="0"
            :count="5"
            :reset-index="true"
            :frontend="true"
            placement="main"
          />
          <CommonPromo
            class="order-3 lg:order-none"
            icon-type="conversation"
            header1="Læs mindre – forstå mere"
            header2="Køb abonnement på Mandag Morgen og bliv klogere på de vigtigste dagsordner"
            cta-label="Abonnér på Mandag Morgen"
            cta="Routes.Subscription"
            disclaimer="Allerede abonnent? <a href='/login' class='underline'>Log ind her</a>"
            hide-if-logged-in
            placement="main"
          />
        </template>
        <template #right>
          <ListMostRead class="hidden lg:block" placement="side" />
          <ListCalendar
            class="order-7 lg:order-none"
            title="Arrangementer på MM"
            :count="2"
            placement="side"
          />
        </template>
      </LayoutColumns>
    </div>
    <CommonDivider class="no-print order-last lg:order-none" />

    <CommonSectionLabel
      class="no-print order-last lg:order-none"
      title="Oplæste artikler"
      subtitle="Lyt til Mandag Morgens artikler"
      link="oplaeste-artikler"
      link-label="ShowAll"
    />
    <ListAudioArticles swiper />
    <AdsConceptAd
      class="no-print hidden lg:block"
      id="mm-lb2"
      :full-width="true"
    />
    <AdsConceptAd
      class="no-print lg:hidden order-15"
      id="mm-mob2"
      :full-width="true"
    />

    <div class="container no-print">
      <LayoutColumns>
        <template #left>
          <ListColumnists
            class="order-17 lg:order-none"
            type="articletype"
            :article-type="124"
            title="Mandag Morgens ledelseseksperter"
          />
        </template>
        <template #main>
          <ListArticles
            class="order-23 lg:order-none"
            :count="1"
            type="articletype"
            :article-type="103"
            :paper="1"
            placement="main"
          />
          <NewsletterSignup class="order-10 lg:order-none" />
        </template>
        <template #right>
          <MagazineWeeklyMagazine class="hidden lg:block" placement="side" />
          <AdsConceptAd class="hidden lg:block" id="mm-rec1" />
          <AdsConceptAd class="lg:hidden order-3" id="mm-mob3" />
        </template>
      </LayoutColumns>
    </div>

    <CommonDivider class="no-print hidden lg:block lg:order-none" />
    <ListJobs
      class="no-print hidden lg:order-none lg:block"
      :count="15"
      :is-slider="true"
    />
    <CommonDivider class="no-print order-last lg:order-none" />
    <div class="container no-print">
      <CommonSectionLabel
        class="no-print order-last lg:order-none"
        title="Seneste artikler"
      />
      <LayoutColumns>
        <template #left>
          <ThemeWeeklyTheme
            class="order-last lg:order-none"
            :last-week="true"
          />
        </template>
        <template #main>
          <ListArticles
            class="order-last lg:order-none"
            :count="8"
            type="instream"
            :show-more="true"
            placement="main"
          />
        </template>
        <template #right>
          <ListMostRead class="hidden lg:block" />
          <AdsConceptAd class="hidden lg:block" id="mm-rec2" />
        </template>
      </LayoutColumns>
    </div>

    <CommonDivider class="no-print order-last lg:order-none" />
    <ListMagazines class="no-print order-last lg:order-none" :count="15" />
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
const indexStore = useIndexStore()

if (!indexStore.currentPaper) {
  throw createError({ statusCode: 404 })
}

useSeoMeta({
  title: indexStore.currentPaperSlug
    ? config.public.site.meta.title.postfix.short +
      indexStore.currentPaper?.Name
    : config.public.site.meta.title.postfix.long,
})
</script>
