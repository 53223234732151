import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "13"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M9.59 4H8V1c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1v3H2.41c-.89 0-1.34 1.08-.71 1.71L5.29 9.3c.39.39 1.02.39 1.41 0l3.59-3.59c.63-.63.19-1.71-.7-1.71M0 12c0 .55.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1H1c-.55 0-1 .45-1 1"
    }, null, -1)
  ])))
}
export default { render: render }