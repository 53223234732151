<template>
  <div>
    <CommonListLabel
      :label="'Seneste ugebrev'"
      :link="'/ugebreve'"
      :translate-label="false"
    />
    <CommonDivider
      :dark="true"
      :class="placement === 'side' ? '' : 'mt-0 md:mt-3 mb-3 md:mb-5'"
    />
    <div v-if="magazine" class="mb-3">
      <NuxtLink :to="userStore.isLoggedIn ? '' : 'ugebreve'">
        <CommonImageLoader
          :width="516"
          :height="697"
          class="overflow-hidden rounded-md"
          :src="magazine.ImageUrl"
        />
      </NuxtLink>
      <NuxtLink
        :to="link"
        v-bind="downloadLinkActive ? {
          external: true,
          download: magazine.Name,
          target: '_blank',
          onClick: (e: Event) => handleDownload(e)
        } : {}"
      >
        <CommonButton
          v-if="userStore.isLoggedIn"
          bg="bg-black"
          text="text-white"
          border="border-none"
          class="mt-6 group"
        >
          <template v-if="isDownloading">
            <CommonSpinner class="w-4 h-4 mr-2" />
            Downloader...
          </template>
          <template v-else>
            {{
              downloadLinkActive
                ? 'Download ugebrev'
                : 'Køb abonnement og få adgang'
            }}
            <DownloadIcon
              class="text-white fill-current w-4 h-4 ml-1 inline transition-colors duration-100 group-hover:text-black"
            />
          </template>
        </CommonButton>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import DownloadIcon from '~/assets/icons/icon-action-download.svg?component'
const nuxtApp = useNuxtApp()

const userStore = useUserStore()

withDefaults(
  defineProps<{
    placement?: string
  }>(),
  {
    placement: 'side',
  }
)
const { data: magazine } = await useAsyncData(
  async () => (await nuxtApp.$api.content.magazine(1))[0]
)

const downloadLinkActive = computed(() => {
  return userStore.hasSubscription(1)
})

const link = computed(() => {
  if (!magazine.value) {
    return
  }

  if (downloadLinkActive.value) {
    return new URL(magazine.value.Link).pathname
  } else {
    return { name: 'subscription' }
  }
})

const isDownloading = ref(false)

const handleDownload = async (event: Event) => {
  if (!downloadLinkActive.value || !magazine.value) return
  event.preventDefault()
  isDownloading.value = true

  try {
    const blob = await fetch(new URL(magazine.value.Link).pathname).then((r) =>
      r.blob()
    )
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = magazine.value.Name
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  } catch (error) {
    console.error('Download failed:', error)
  } finally {
    isDownloading.value = false
  }
}
</script>
